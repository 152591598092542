<template>
  <div>
    <div class="left">
      <div
        v-for="(a, i) in list"
        :class="i == active ? 'left-active' : 'left-item'"
        :key="a"
        @click="setActive(i)"
      >
        <img :src="a.ico" alt="" style="margin-right:10px;" />{{ a.value }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "tabs",
  data() {
    return {
      active: 0,
      list: [
        { ico: "/img/wxlogo.png", value: "微信支付" },
        { ico: "/img/zhilogo.png", value: "支付宝" },
        { ico: "/img/uplogo.png", value: "银联转账" }
      ]
    };
  },
  methods: {
    setActive(index) {
      this.active = index;
      this.$emit("active", index);
    }
  }
};
</script>
<style scoped>
.left {
  width: 234px;
  text-align: center;
}
.left-item {
  height: 90px;
  line-height: 90px;
  background: rgba(240, 240, 240, 1);
  border: 1px solid rgba(217, 217, 217, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-active {
  height: 90px;
  line-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
