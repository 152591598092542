<template>
  <div class="capacity">
    <div>
      <PaperHead />
    </div>
    <div class="pay_min">
      <h1>容量500M</h1>
      <div class="min_package">
        <dl
          :class="0 == check ? 'left-active' : 'left-item'"
          @click="check = 0"
        >
          <span>推荐</span>
          <dt>
            500M
            <p>13.9元/月</p>
          </dt>
        </dl>
        <dl
          :class="1 == check ? 'left-active' : 'left-item'"
          @click="check = 1"
        >
          <dt>
            500M
            <p>13.9元/月</p>
          </dt>
        </dl>
        <dl
          :class="2 == check ? 'left-active' : 'left-item'"
          @click="check = 2"
        >
          <dt>
            500M
            <p>13.9元/月</p>
          </dt>
        </dl>
      </div>
      <div class="paypay">
        <tab @active="tab"></tab>
        <div v-show="active == 0" class="codepay">
          <dl>
            <dt class="wx"><img :src="this.wxdd" alt="" /></dt>
            <dd>
              <p>微信扫码，支付 <span>13.9</span>元</p>
              <p>xpaper账号：小明</p>
            </dd>
          </dl>
        </div>
        <div v-show="active == 1" class="codepay">
          <dl>
            <dt><img :src="this.ali" alt="" /></dt>
            <dd>
              <p>支付宝扫码，支付 <span>13.9</span>元</p>
              <p>xpaper账号：北京——小明</p>
            </dd>
          </dl>
        </div>
        <div v-show="active == 2">
          <p>应付价格 <span>13.9</span></p>
          <p>汇款至中国招商银行下的 「北京海腾时代科技有限公司」账户。</p>
          <table>
            <tr>
              <td>开户银行</td>
              <td>账户名称</td>
              <td>账号</td>
              <td>电汇代码</td>
            </tr>
            <tr>
              <td>中国招商银行北京回龙观支行</td>
              <td>北京海腾时代科技有限公司</td>
              <td>1109 1227 0010 601</td>
              <td>SDASFAOSFDSO</td>
            </tr>
          </table>

          <p class="footp">
            请在转账或汇款时在留言栏或备注栏注明您单位名称或姓名，以及您的联系邮箱，以便我们及时登记。
          </p>
          <p class="footp">
            建议您在完成汇款后把银行回单发送到
            <a href="xpaper@hitencent.com" target="_blank" rel="noopener"
              >xpaper@hitencent.com</a
            >
          </p>
        </div>
      </div>
    </div>
    <div class="maskbox">
      <div class="mask">
        <img src="/public/img/ok.png" alt="" />
        <p style="text-align: center;">支付成功!</p>
      </div>
    </div>
  </div>
</template>
<script>
import PaperHead from "@components/layout/PaperHead";
import tab from "./tab.vue";
export default {
  name: "Payment",
  data() {
    return { active: 0, check: 0, wxdd: "", ali: "" };
  },
  methods: {
    getwx() {
      this.$axios
        .post("https://pay.xcanvas.cn/wxpay/order/create/v2", {
          //生成订单号
          goodsId: 38,
          memberId: "1218818994582777857",
          months: 1,
          personNum: 10
        })
        .then(res => {
          // this.wxdd = res
          this.$axios({
            method: "get",
            url: "https://pay.xcanvas.cn/wxpay/pay?orderId=" + res.data,
            responseType: "blob"
          }).then(response => {
            this.wxdd = window.URL.createObjectURL(response);
            clearInterval(time);
            var time = setInterval(() => {
              this.$axios
                .get("https://pay.xcanvas.cn/payOrder?orderId=" + res.data)
                .then(res => {
                  if (res.data.status == 1) {
                    alert("zhifucheng");
                    clearInterval(time);
                  }
                }); //查询订单
            }, 6000);
          });
        });
    },

    getali() {
      this.$axios
        .post("https://pay.xcanvas.cn/alipay/order/create/v2", {
          //生成订单号
          goodsId: 38,
          memberId: "1218818994582777857",
          months: 1,
          personNum: 10
        })
        .then(res => {
          // this.wxdd = res
          this.$axios({
            method: "get",
            url: "https://pay.xcanvas.cn/alipay/qrcode?orderId=" + res.data,
            responseType: "blob"
          }).then(response => {
            this.ali = window.URL.createObjectURL(response);
            clearInterval(time);
            var time = setInterval(() => {
              this.$axios
                .get("https://pay.xcanvas.cn/payOrder?orderId=" + res.data)
                .then(res => {
                  if (res.data.status == 1) {
                    alert("zhifucheng");
                    clearInterval(time);
                  }
                }); //查询订单
            }, 6000);
          });
        });
    },

    tab(index) {
      this.active = index;
    }
  },
  mounted() {
    this.getwx();
    this.getali();
  },
  components: {
    PaperHead,
    tab
  }
};
</script>
<style lang="scss" scoped>
dd {
  margin: 0;
}
.pay_min {
  width: 78%;
  margin: 0 auto;
}
h1 {
  font-size: 38px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  font-size: 38px;
  margin-top: 50px;
  margin-bottom: 32px;
  // clear: both;
}
.min_package {
  width: 100%;
  height: 96px;
  dl {
    width: 30%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid rgba(204, 204, 204, 1);
    box-sizing: border-box;
    padding: 17px 17px 17px 25px;
    position: relative;
    float: left;
    margin-right: 3%;
    span {
      position: absolute;
      top: 0;
      left: -2px;
      display: inline-block;
      width: 48px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      background: linear-gradient(
        137deg,
        rgba(251, 112, 100, 1) 0%,
        rgba(245, 142, 52, 1) 100%
      );
      border-radius: 8px 0px 16px 0px;
      font-size: 11px;
      font-family: PingFangSC-Medium, PingFang SC;
    }
    dt {
      display: inline-block;
      font-size: 29px;
      p {
        font-size: 18px;
      }
    }
  }
}
.paypay {
  width: 100%;
  height: 270px;
  border-radius: 9px;
  border: 1px solid rgba(204, 204, 204, 1);
  margin: 0 auto;
  margin-top: 55px;
  display: flex;
  flex-direction: row;
  .codepay {
    width: 70%;
    box-sizing: border-box;
    padding: 45px 0;
    dl {
      width: 60%;
      margin: 0 auto;
      dt {
        width: 174px;
        height: 174px;
        line-height: 174px;
        text-align: center;
        border: solid 1px #000;
        float: left;
        img {
          width: 100%;
          height: 100%;
        }
      }
      dd {
        float: right;
        margin-top: 65px;
        & p:first-child {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 28px;
          span {
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(245, 142, 52, 1);
          }
        }
        & p:nth-child(2) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 30px;
        }
      }
    }
  }
  & div:nth-child(4) {
    width: 60%;
    text-align: center;
    margin: 0 auto;
    & p:first-child {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin-top: 25px;
      span {
        color: #f58e34;
      }
    }
    & p:nth-child(2) {
      font-size: 14px;
      margin: 14px 0;
    }
    table {
      width: 100%;
      height: 94px;
      font-size: 10px;
      text-align: center;
      border: 1px solid rgba(228, 228, 228, 1);
      margin-bottom: 15px;
      & tr:first-child {
        font-size: 12px;
        font-weight: 400;
        height: 38px;
        background: rgba(244, 244, 244, 1);
      }
    }
    .footp {
      font-size: 9px;
      line-height: 20px;
    }
  }
}
.left-active {
  background: linear-gradient(
    111deg,
    rgba(255, 248, 224, 1) 0%,
    rgba(255, 245, 239, 1) 100%
  );
  border-radius: 8px;
  border: 3px solid rgba(245, 166, 35, 1) !important;
}

.maskbox {
  width: 100vw;
  height: 100vh;
  background: antiquewhite;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(129, 127, 127, 0.5);
  display: none;
}
.mask {
  box-sizing: border-box;
  padding: 30px 0;
  width: 350px;
  height: 200px;
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 5px;
  text-align: center;
}
.mask img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
</style>
