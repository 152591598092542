<template>
  <div class="capacity">
    <div>
      <PaperHead />
    </div>
    <div class="pay_min">
      <h1>{{ dataList.top.title }}</h1>
      <div class="min_package">
        <dl
          @click="check = index"
          :class="check == index ? 'dl' : ''"
          v-for="(item, index) in dataList.top.list"
          :key="index"
        >
          <span v-if="index == 0">推荐</span>
          <dt>
            {{ item.descr }}
            <p>{{ item.price }}</p>
          </dt>
          <router-link to="payment" rel="noopener">
            <dd v-show="check == index" @click="goumai(item.id)">立即购买</dd>
          </router-link>
        </dl>
      </div>

      <h1>推荐升级套餐</h1>
      <div class="min_vip">
        <dl>
          <dt>
            <p>团队SVIP1</p>
            <p><span>358.8 </span>元/年</p>
            <p>
              <span style="color: #F69102">
                <i style="background: #F69102"></i> 800M存储空间
              </span>
              <span> <i></i> 1000M流量 </span>
              <span> <i></i> 所有模板使用权限 </span>
              <span> <i></i> 无线创建项目 </span>
            </p>
          </dt>
          <router-link to="payment" rel="noopener">
            <dd>立即购买</dd>
          </router-link>
        </dl>
        <dl>
          <dt>
            <p>团队SVIP2</p>
            <p><span>600 </span>元/年</p>
            <p>
              <span style="color: #F69102">
                <i style="background: #F69102"></i> 2000M存储空间
              </span>
              <span> <i></i> 2000M流量 </span>
              <span> <i></i> 所有模板使用权限 </span>
              <span> <i></i> 无线创建项目 </span>
            </p>
          </dt>
          <router-link to="payment" rel="noopener">
            <dd>立即购买</dd>
          </router-link>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import PaperHead from "@components/layout/PaperHead";

let data = {
  top: {
    title: "储存容量套餐",
    list: [
      {
        a: "500MB",
        b: "13.9元/月"
      },
      {
        a: "100MB",
        b: "3.9元/月"
      },
      {
        a: "1000MB",
        b: "23.9元/月"
      },
      {
        a: "2000MB",
        b: "46.9元/月"
      }
    ]
  },
  bottom: {
    title: "推荐升级套餐",
    list: [{}]
  }
};
// let data1 = {
//   top: {
//     title: "流量套餐",
//     list: [
//       {
//         a: "500MB",
//         b: "6元"
//       },
//       {
//         a: "100MB",
//         b: "2元"
//       },
//       {
//         a: "1000MB",
//         b: "12元"
//       },
//       {
//         a: "2000MB",
//         b: "24元"
//       }
//     ]
//   },
//   bottom: {
//     title: "推荐升级套餐",
//     list: [{}]
//   }
// };
export default {
  name: "Capacity",
  data() {
    return {
      check: 0,
      dataList: {},
      data: {
        level: 7
      }
    };
  },
  components: {
    PaperHead
  },
  mounted() {
    if (this.$route.query.type == "capacity") {
      this.dataList = data;
      //这里面请求就行
    } else if (this.$route.query.type == "flow") {
      this.$axios
        .get("https://pay.xcanvas.cn/goods/list", this.data)
        .then(res => {
          this.dataList.top.list = res.data.records;
          this.dataList.top.title = "流量套餐";
        });
    }
  }
};
</script>
<style lang="scss" scoped>
dd {
  margin: 0;
}
.pay_min {
  width: 78%;
  margin: 0 auto;
}
h1 {
  font-size: 38px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  font-size: 38px;
  margin-top: 50px;
  margin-bottom: 32px;
}
.min_package {
  width: 100%;
  display: flex;
  justify-content: space-around;
  dl {
    width: 23%;
    height: 96px;
    border-radius: 8px;
    border: 1px solid rgba(204, 204, 204, 1);
    box-sizing: border-box;
    padding: 15px;
    position: relative;
    span {
      position: absolute;
      top: 0;
      left: -2px;
      display: inline-block;
      width: 48px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      background: linear-gradient(
        137deg,
        rgba(251, 112, 100, 1) 0%,
        rgba(245, 142, 52, 1) 100%
      );
      border-radius: 8px 0px 16px 0px;
      font-size: 11px;
      font-family: PingFangSC-Medium, PingFang SC;
    }
    dt {
      display: inline-block;
      font-size: 29px;
      p {
        font-size: 18px;
      }
    }
    dd {
      display: inline-block;
      width: 43%;
      height: 40px;
      background: rgba(245, 142, 52, 1);
      border-radius: 8px;
      font-size: 19px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 40px;
      text-align: center;
      float: right;
      margin-top: 10px;
    }
  }
}
.min_vip {
  width: 100%;
  dl {
    width: 100%;
    height: 208px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 55px;
    margin-bottom: 24px;
    dt {
      float: left;
      & p:first-child {
        font-size: 34px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(51, 51, 51, 1);
        margin: 24px 0 10px 0;
      }
      & p:nth-child(2) {
        font-size: 16px;
        margin-bottom: 20px;
        span {
          font-size: 38px;
          font-family: Arial-Black, Arial;
          font-weight: 900;
          color: rgba(245, 67, 67, 1);
        }
      }
      & p:nth-child(3) {
        font-size: 14px;
        span {
          margin-right: 34px;
          i {
            display: inline-table;
            width: 5px;
            height: 5px;
            margin-bottom: 3px;
            border-radius: 50%;
            background: #666666;
            margin-right: 20px;
          }
        }
      }
    }
    dd {
      float: right;
      width: 205px;
      height: 56px;
      border-radius: 8px;
      border: 1px solid rgba(249, 144, 0, 1);
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(245, 166, 35, 1);
      line-height: 56px;
      text-align: center;
      margin-top: 76px;
    }
  }
}
.dl {
  background: linear-gradient(
    111deg,
    rgba(255, 248, 224, 1) 0%,
    rgba(255, 245, 239, 1) 100%
  );
  border-radius: 8px;
  border: 3px solid rgba(245, 166, 35, 1) !important;
}
</style>
