<template>
  <div class="vip">
    <div>
      <PaperHead />
    </div>
    <h1>开通VIP,体验更好的服务</h1>
    <div class="pay_min">
      <div class="personal_vip">
        <h3>个人VIP</h3>
        <img src="/img/Group53.png" alt="" />
        <div class="div">
          <span style="float: left; font-size:18px">1个月</span>
          <span style="float: right; font-size:14px;color:#F54343"
            ><s style="color:#9999">(60元)</s> 9.9元</span
          >
        </div>
        <div class="div">
          <span style="float: left; font-size:18px">1个月</span>
          <span style="float: right; font-size:14px;color:#F54343"
            ><s style="color:#9999">(60元)</s> 9.9元</span
          >
        </div>
        <div class="div">
          <span style="float: left; font-size:18px">1个月</span>
          <span style="float: right; font-size:14px;color:#F54343"
            ><s style="color:#9999">(60元)</s> 9.9元</span
          >
        </div>
        <div class="div">
          <span style="float: left; font-size:18px">1个月</span>
          <span style="float: right; font-size:14px;color:#F54343"
            ><s style="color:#9999">(60元)</s> 9.9元</span
          >
        </div>
        <p>无限创建项目</p>
        <p>200M储存容量</p>
        <p>500M下载流量</p>
        <p>所有模板使用权限</p>
        <p>支持导出高清PDF个格式</p>
        <p>动态信息第一时间获取</p>
        <button>立即购买</button>
      </div>

      <div class="personal_vip">
        <img
          src="/img/Group3.png"
          alt=""
          style="top: -10px;
    right: -10px;
    position: absolute;"
        />
        <h3>个人sVIP</h3>
        <img src="/img/Group54.png" alt="" />
        <div class="div">
          <span style="float: left; font-size:18px">1个月</span>
          <span style="float: right; font-size:14px;color:#F54343"
            ><s style="color:#9999">(60元)</s> 9.9元</span
          >
        </div>
        <div class="div">
          <span style="float: left; font-size:18px">1个月</span>
          <span style="float: right; font-size:14px;color:#F54343"
            ><s style="color:#9999">(60元)</s> 9.9元</span
          >
        </div>
        <div class="div">
          <span style="float: left; font-size:18px">1个月</span>
          <span style="float: right; font-size:14px;color:#F54343"
            ><s style="color:#9999">(60元)</s> 9.9元</span
          >
        </div>
        <div class="div">
          <span style="float: left; font-size:18px">1个月</span>
          <span style="float: right; font-size:14px;color:#F54343"
            ><s style="color:#9999">(60元)</s> 9.9元</span
          >
        </div>
        <p>无限创建项目</p>
        <p>500M储存容量</p>
        <p>1000M下载流量</p>
        <p>所有模板使用权限</p>
        <p>支持导出高清PDF个格式</p>
        <p>动态信息第一时间获取</p>
        <p>历史版本管理</p>
        <p>项目加密</p>
        <p>项目备份&还原</p>
        <button>立即购买</button>
      </div>

      <div class="personal_vip">
        <h3>团队VIP</h3>
        <img src="/img/Group51.png" alt="" />
        <h5 style="color:#f58e34; font-size:24px;margin:10px 0 10px 0;">
          49.9元/人/月
        </h5>
        <div class="cc">
          <el-select v-model="value" placeholder="请选择您的团队人数">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="cc">
          <el-select v-model="value" placeholder="请选择您购买的时间">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <h4 style="color:#f58e34;font-size:36px;text-align:center">
          <span
            style="font-size:14px;font-weight:500;color:rgba(153,153,153,1);"
            >原价448.5元</span
          >428.5元
        </h4>
        <p>团队管理功能</p>
        <p>无限创建团队</p>
        <p>每位成员800M储存空间</p>
        <p>每位成员1000M下载流量</p>
        <p>所有模板使用权限</p>
        <p>支持导出高清PDF格式</p>
        <p>动态信息第一时间获取</p>
        <p>历史版本管理</p>
        <p>项目加密</p>
        <p>项目备份&还原</p>
        <p>游客可评论功能&还原</p>
        <button>立即购买</button>
      </div>

      <div class="personal_vip">
        <h3>团队SVIP</h3>
        <img src="/img/Group51.png" alt="" />
        <h5 style="color:#f58e34; font-size:24px;margin:10px 0 10px 0;">
          49.9元/人/月
        </h5>
        <div class="cc">
          <el-select v-model="value" placeholder="请选择您的团队人数">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="cc">
          <el-select v-model="value" placeholder="请选择您购买的时间">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <h4 style="color:#f58e34;font-size:36px;text-align:center">
          <span
            style="font-size:14px;font-weight:500;color:rgba(153,153,153,1);"
            >原价448.5元</span
          >428.5元
        </h4>
        <p>团队管理功能</p>
        <p>无限创建团队</p>
        <p>每位成员2000M储存空间</p>
        <p>每位成员2000M下载流量</p>
        <p>所有模板使用权限</p>
        <p>支持导出高清PDF格式</p>
        <p>动态信息第一时间获取</p>
        <p>历史版本管理</p>
        <p>项目加密</p>
        <p>项目备份&还原</p>
        <p>游客可评论功能&还原</p>
        <p>视频通话</p>
        <button>立即购买</button>
      </div>
    </div>
  </div>
</template>
<script>
import PaperHead from "@components/layout/PaperHead";
export default {
  name: "vippay",
  data() {
    return {
      check: 0,
      options: [
        {
          value: "选项1",
          label: ""
        },
        {
          value: "选项2",
          label: "双皮奶"
        },
        {
          value: "选项3",
          label: "蚵仔煎"
        },
        {
          value: "选项4",
          label: "龙须面"
        },
        {
          value: "选项5",
          label: "北京烤鸭"
        }
      ],
      value: ""
    };
  },
  components: {
    PaperHead
  }
};
</script>
<style lang="scss" scoped>
button {
  outline: none;
  border: none;
  cursor: pointer;
}
.pay_min {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
h1 {
  font-size: 38px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  font-size: 38px;
  margin-top: 50px;
  margin-bottom: 32px;
  text-align: center;
}
.personal_vip {
  width: 23%;
  height: 820px;
  background: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  padding: 0 40px;
  position: relative;
  text-align: center;
  &:nth-child(4) {
    p {
      line-height: 28px;
      &:nth-child(n + 15) {
        color: #f58e34;
      }
    }
  }
  &:nth-child(3) {
    p {
      line-height: 29px;
      &:nth-child(n + 15) {
        color: #f58e34;
      }
    }
  }
  &:nth-child(2) {
    p {
      line-height: 27px;
      &:nth-child(n + 15) {
        color: #f58e34;
      }
    }
  }
  h3 {
    text-align: center;
    font-size: 36px;
    margin: 20px 0;
  }
  .div {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border: 1px solid rgba(204, 204, 204, 1);
    box-sizing: border-box;
    padding: 0 15px;
    margin: 20px 0;
  }
  p {
    clear: both;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 41px;
  }
  button {
    width: 280px;
    height: 60px;
    background: rgba(245, 142, 52, 1);
    border-radius: 5px;
    text-align: center;
    line-height: 60px;
    color: #fff;
    margin: 0 auto;
    margin-top: 20px;
  }
  ::v-deep .cc {
    .el-select {
      width: 100%;
      .el-input__inner {
        width: 100%;
        height: 60px;
        line-height: 60px;
        border: 1px solid rgba(204, 204, 204, 1);
        box-sizing: border-box;
        padding: 0 15px;
        margin: 10px 0;
      }
    }
  }
}
</style>
<style>
.el-select-dropdown__item {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 55px !important;
  line-height: 55px !important;
  text-align: center !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.el-select-dropdown__item.selected {
  background: #f5a623;
  color: #fff;
}
</style>
